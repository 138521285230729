<section class="contact" id="contato">
  <div class="container">
    <div class="content">
      <div class="contact__title">
        <h2>ENTRE EM CONTATO</h2>
        <p>Nós estamos sempre aqui para ajudar com o que você precisar.</p>
      </div>
      <form class="form" (ngSubmit)="submit(form)" #form="ngForm">
        <div class="form-control">
          <div class="form-control__icon">
            <div
              class="svg-icon"
              aria-label="Nome"
              [inlineSVG]="'assets/icons/account.svg'"
            ></div>
          </div>
          <input
            type="text"
            name="nome"
            placeholder="Nome completo"
            autocomplete="name"
            [(ngModel)]="contatoMensagem.nome"
            #nome="ngModel"
            required
          />
          <i
            class="fas fa-exclamation-circle"
            *ngIf="nome.invalid && (nome.touched || nome.dirty)"
          ></i>
        </div>
        <div class="form-control">
          <div class="form-control__icon">
            <div
              class="svg-icon"
              aria-label="E-mail"
              [inlineSVG]="'assets/icons/mail.svg'"
            ></div>
          </div>
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            autocomplete="email"
            [(ngModel)]="contatoMensagem.email"
            #email="ngModel"
            required
          />
          <i
            class="fas fa-exclamation-circle"
            *ngIf="email.invalid && (email.touched || email.dirty)"
          ></i>
        </div>
        <div class="form-control">
          <div class="form-control__icon">
            <div
              class="svg-icon"
              aria-label="Telfone"
              [inlineSVG]="'assets/icons/phone.svg'"
            ></div>
          </div>
          <input
            type="tel"
            name="telefone"
            placeholder="Telefone"
            mask="(00) 0000-0000 || (00) 0 0000-0000"
            [(ngModel)]="contatoMensagem.telefone"
            #telefone="ngModel"
            required
          />
          <i
            class="fas fa-exclamation-circle"
            *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)"
          ></i>
        </div>
        <textarea
          name="mensagem"
          id="mensagem"
          placeholder="Mensagem"
          [(ngModel)]="contatoMensagem.mensagem"
          cols="30"
        ></textarea>
        <button class="btn btn-default block" type="submit">ENVIAR</button>
      </form>
    </div>
    <app-maps-google
      [lat]="-29.15147422229783"
      [lng]="-51.223872645516025"
    ></app-maps-google>
  </div>
</section>

<footer class="footer">
  <div class="container">
    <div class="footer-wrapper">
      <div class="footer-item">
        <div class="logo">
          <img src="assets/logo-black.png" alt="Logo" />
        </div>
      </div>
      <div class="footer-item">
        <h3>INSTITUCIONAL</h3>
        <ul>
          <li class="footer-item__item">
            <a routerLink="/" class="footer-item__link"> Home </a>
          </li>
          <li class="footer-item__item">
            <a routerLink="/sobre" class="footer-item__link"> Sobre </a>
          </li>
          <li class="footer-item__item">
            <a routerLink="/contato" class="footer-item__link"> Contato </a>
          </li>
        </ul>
      </div>
      <div class="footer-item">
        <h3>LINHA DE PRODUTOS</h3>
        <ul>
          <li class="footer-item__item" *ngFor="let c of categorias">
            <a class="footer-item__link" [routerLink]="['/produtos', c.slug]">
              {{ c.nome }}
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-item">
        <h3>MÍDIAS SOCIAIS</h3>
        <ul class="social-media">
          <li class="social-media__item" *ngIf="contatoGeral.linkFacebook">
            <a
              [href]="contatoGeral.linkFacebook"
              target="_blank"
              class="social-media__link"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="social-media__item" *ngIf="contatoGeral.linkInstagram">
            <a
              [href]="contatoGeral.linkInstagram"
              target="_blank"
              class="social-media__link"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li class="social-media__item" *ngIf="contatoGeral.linkLinkedin">
            <a
              [href]="contatoGeral.linkLinkedin"
              target="_blank"
              class="social-media__link"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-item">
        <h3>CONTATOS</h3>
        <ul>
          <li class="footer-item__item">
            <a
              [href]="'mailto:' + contatoGeral.email"
              class="footer-item__link"
            >
              <i class="fas fa-envelope"></i>
              <span>{{ contatoGeral.email }}</span>
            </a>
          </li>
          <li class="footer-item__item">
            <a
              [href]="'https://wa.me/55' + contatoGeral.whatsApp"
              class="footer-item__link"
            >
              <i class="fab fa-whatsapp"></i>
              <span>{{ contatoGeral.whatsApp | mask: "(00) 00000.0000" }}</span>
            </a>
          </li>
          <li class="footer-item__item">
            <a
              [href]="'tel:' + contatoGeral.telefone"
              class="footer-item__link"
            >
              <i class="fas fa-phone-alt"></i>
              <span>{{ contatoGeral.telefone | mask: "(00) 0000.0000" }}</span>
            </a>
          </li>
          <li class="footer-item__item">
            <div class="footer-item__link">
              <i class="fas fa-map-marker-alt"></i>
              <span>
                {{ contatoGeral.rua }}, {{ contatoGeral.numeroEndereco }} -
                {{ contatoGeral.bairro }}, {{ contatoGeral.cidade }} -
                {{ contatoGeral.uf }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <p>
        &copy; 2020 - Todos os direitos reservador Lubsul Equipamentos e
        Antifricção Ltda.
      </p>
      <a href="https://yourway.digital" class="yourway">
        <img src="assets/icons/yourway.png" alt="YourWay Digital" />
      </a>
    </div>
  </div>
</footer>
