import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Categoria } from 'src/app/models/categoria.model';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  contatoMensagem: ContatoMensagem = new ContatoMensagem();
  categorias: Categoria[] = [];
  loading: boolean = false;

  constructor(
    public _data: DataService,
    public helper: HelperService,
    public _alert: AlertService
  ) { }

  ngOnInit(): void {
    this._data.getContatoGeral().subscribe(res => this.contatoGeral = res);
    this._data.getCategorias(-99, -99).subscribe(res => this.categorias = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this._data.inserirContatoMensagem(this.contatoMensagem)
      .subscribe((res: any) => {
        this._alert.present('Mensagem enviada', 'Em breve entraremos em contato.');
        f.reset();
        this.loading = false;
      }, e => {
        this._alert.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loading = false;
      })
  }

}
