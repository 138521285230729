import { Subcategoria } from './subcategoria.model';

export class Categoria {

    id: number;
    nome: string = '';
    imagem: string = '';
    icone: string = '';
    situacao: string = 'A';
    slug: string  = '';
    ordem: number = 99;
    subcategorias: Subcategoria[] = [];
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}