import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header-interno',
  templateUrl: './header-interno.component.html',
  styleUrls: ['./header-interno.component.scss']
})
export class HeaderInternoComponent implements OnInit {

  @Input('title') title: string;
  @Input('background') background: string;

  constructor(
    public global: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
