export class Sobre {

    id: any;
    sobre: string = '';
    imagem: string = '';
    missao: string = '';
    visao: string = '';
    valores: string = '';
    caracteristica1: string = '';
    caracteristica2: string = '';
    caracteristica3: string = '';
    caracteristica4: string = '';
    valorCaracteristica1: string = '';
    valorCaracteristica2: string = '';
    valorCaracteristica3: string = '';
    valorCaracteristica4: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}