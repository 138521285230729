import { Categoria } from './categoria.model';

export class Subcategoria {

    id: number;
    nome: string = '';
    descricao: string = '';
    situacao: string = 'A';
    ordem: number = 99;
    categoria: Categoria = new Categoria();
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}